<template>
  <div class="box">
    <h6 class="title is-6">Form Regenerator</h6>
    <div class="field has-addons">
      <div class="control is-expanded">
        <input class="input" type="text" placeholder="Form ID" v-model="formId" />
      </div>
      <div class="control">
        <a class="button is-link" @click="submit()"> Regenerate </a>
      </div>
    </div>
    <FlashMessage :flash="flash" />
  </div>
</template>

<script>
import { api } from '@/modules/util'

export default {
  name: 'FormRegenerator',
  data() {
    return {
      formId: null,
      flash: [],
    }
  },
  methods: {
    async submit() {
      try {
        await api.post('/admin/regeneratePdf', { formId: this.formId })
        this.flash.push({ msg: 'Form regenerated!' })
      } catch (err) {
        this.flash.push({ msg: 'Form regeneration failed!', type: 'error' })
      }
    },
  },
}
</script>
