<template>
  <div>
    <h4 class="title is-4">Admin</h4>
    <div class="box">
      <h6 class="title is-6">Users</h6>
      <div class="field has-addons">
        <p class="control">
          <span class="select">
            <select v-model="query">
              <option value="id">User ID</option>
              <option value="email">Email</option>
            </select>
          </span>
        </p>
        <div class="control is-expanded">
          <input class="input" type="text" placeholder="Find" v-model="value" />
        </div>
        <div class="control">
          <a class="button is-link" @click="find()"> Find </a>
        </div>
      </div>
      <br />
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="u in users" :key="u.id">
            <td>{{ u.id }}</td>
            <td>{{ u.email }}</td>
            <td class="has-text-right"><a @click="login(u.id)">Log In</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <FormRegenerator />
  </div>
</template>


<script>
import { api } from '@/modules/util'
import FormRegenerator from '@/components/FormRegenerator.vue'

export default {
  name: 'Admin',
  props: ['user'],
  components: { FormRegenerator },
  data() {
    return {
      users: [],
      query: 'id',
      value: '',
    }
  },

  methods: {
    async init() {
      try {
        const res = await api.get('/admin/users')
        this.users = res.data
      } catch (err) {
        // TODO
      }
    },
    async find() {
      try {
        const res = await api.get(`/admin/users?${this.query}=${this.value}`)
        this.users = res.data
      } catch (err) {
        // TODO
      }
    },
    async login(id) {
      try {
        await api.get(`/admin/users/${id}/login`)
        this.$emit('init')
        this.$router.push({ name: 'Settings' })
      } catch (err) {
        // TODO
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
